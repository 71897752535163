<template>
  <section class="new-pipeline-module-component module-container">
    <div class="inner-container">
      <div class="title">
        <h2
          class="title-inner"
          v-html="data.title" />
        <BicolorLine class="pipeline-bicolor-line" />
      </div>
      <div class="labels">
        <div>Approach</div>
        <div>Focus</div>
        <div>Early Clinical Development</div>
        <div>Registrational Trial</div>
      </div>
      <ul class="pipelines">
        <li
          v-for="(pipeLine, pipeIdx) in data.items"
          ref="pipeline"
          :key="'pipeline' + pipeIdx"
          class="pipeline">
          <div class="pipeline-title">
            <h3
              class="pipeline-title-inner"
              v-html="pipeLine.title" />
            <button
              v-show="pipeLine?.Text.length > 0 || pipeLine.cta_text.length > 0"
              class="pipeline-title-toggle"
              :class="{ active: open[pipeIdx] }"
              @click="toggle(pipeIdx)">
              <img src="@/assets/img/caret-down-progressive.svg" />
            </button>
          </div>
          <Vue3SlideUpDown
            v-model="open[pipeIdx]"
            class="pipeline-inner">
            <div class="pipeline-content-container">
              <div class="pipeline-inner-container w-full lg:w-1/2">
                <div
                  class="pipeline-inner-text max-w-[520px]"
                  v-html="pipeLine.Text" />
              </div>
              <div
                v-if="pipeLine.clinTrial"
                class="pipeline-inner-trial">
                <div
                  class="pipeline-inner-trial-text"
                  v-html="pipeLine.cta_text" />
                <a
                  :href="pipeLine.clinTrial.url"
                  target="_blank"
                  class="small-btn"
                  >{{ pipeLine.clinTrial.title }}</a
                >
              </div>
            </div>
          </Vue3SlideUpDown>
          <div
            v-show="formatData(pipeIdx).length > 0"
            class="pipeline-mobile">
            <div
              v-for="(item, itemIdx) in formatData(pipeIdx)"
              :key="'approach' + itemIdx"
              class="pipeline-mobile-approach">
              <div class="pipeline-mobile-approach-top">
                <div class="kvp approach">
                  <span class="label approach-left">
                    <strong>Approach:</strong>
                  </span>
                  <span class="value approach-right">
                    {{ item.parent }}
                  </span>
                </div>
                <div class="kvp focus">
                  <span class="label focus-left">
                    <strong>Focus:</strong>
                  </span>
                  <span
                    v-if="!item.child.image_and_text"
                    class="value focus-right">
                    {{ item.child.focus }}
                  </span>
                  <div
                    v-else
                    class="img-container">
                    <span class="value focus-right mr-2">
                      {{ item.child.focus }}
                    </span>
                    <img
                      class="child-img"
                      :src="item.child.image.url"
                      :alt="item.child.image.alt" />
                    <span
                      class="value superscript"
                      :style="`color: ${item.child.color_to};`">
                      {{ item.child.superscript }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="pipeline-mobile-approach-bottom">
                <div
                  :style="getBarStyle(item.child)"
                  class="progress-bar-container">
                  <div
                    :style="{
                      width: `${item.child.prog_mob}%`,
                    }">
                    <div class="progress-bar" />
                  </div>
                </div>
                <span class="stage">{{ item.child.stage }}</span>
              </div>
            </div>
          </div>
          <div class="pipeline-desktop">
            <div
              v-for="(item, itemIdx) in pipeLine.info"
              :key="'dt-approach' + itemIdx"
              class="pipeline-desktop-item">
              <div
                class="parent"
                v-html="item.approach" />
              <div class="children">
                <template
                  v-for="(child, childIdx) in item.focus"
                  :key="'dt-child' + childIdx">
                  <div
                    v-if="!child.image_and_text"
                    class="child"
                    v-html="child.focus" />
                  <div
                    v-else
                    class="img-container">
                    <div
                      class="child mr-10"
                      v-html="child.focus" />
                    <img
                      class="child-img"
                      :src="child.image.url"
                      :alt="child.image.alt" />
                    <span
                      class="child superscript"
                      :style="`color: ${child.color_to};`">
                      {{ child.superscript }}
                    </span>
                  </div>
                </template>
              </div>
              <div class="progress">
                <div
                  v-for="(child, childIdx) in item.focus"
                  :key="'dt-child' + childIdx"
                  class="progbar">
                  <div
                    class="progress-bar-container"
                    :style="getBarStyle(child)">
                    <div
                      :style="{
                        height: '100%',
                        width: `${child.prog}%`,
                      }">
                      <div class="progress-bar" />
                    </div>
                  </div>
                </div>
                <div class="divider" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="disclaimer pt-1 mb-0 pb-0">
        <p
          class="footnotes"
          v-html="data.footnotes" />
      </div>
      <div class="other mt-4 bg-white p-6 rounded-xl">
        <div class="other-left">
          <div
            class="other-left-text font-extrabold"
            v-html="data.other.bottom_title" />
          <BicolorLine class="pipeline-bicolor-line my-5" />
        </div>
        <div class="other-right">
          <div
            class="other-right-content"
            v-html="data.other.bottom_text" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import BicolorLine from '@/components/utility/BicolorLine.vue'
import Vue3SlideUpDown from 'vue3-slide-up-down'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const pipeline = ref()

const open = ref([])

const props = defineProps(['data'])

const toggle = (idx) => {
  open.value[idx] = !open.value[idx]
}

const formatData = (iargIdx) => {
  if (!props.data.items[iargIdx].info) return []
  const initial = props.data.items[iargIdx].info
  const formatted = []
  initial.forEach((item) => {
    item.focus.forEach((child) => {
      formatted.push({
        parent: item.approach,
        child: child,
      })
    })
  })
  return formatted
}

onMounted(() => {
  gsap.fromTo(
    '.progress-bar',
    {
      width: '0%',
    },
    {
      scrollTrigger: {
        trigger: '.new-pipeline-module-component',
        element: '.progress-bar',
        start: 'top 80%',
        end: 'bottom 80%',
        markers: false,
        id: 'new-values',
      },
      width: '100%',
      duration: 1.5,
      ease: 'power2.out',
      stagger: 0.2,
    }
  )
})

const getBarStyle = (item) => {
  return {
    '--barfrom': `${item.color_from}`,
    '--barto': `${item.color_to}`,
  }
}

onBeforeUnmount(() => {
  const anim = ScrollTrigger.getById('new-values')
  if (anim) anim.kill()
})

onMounted(() => {
  if (pipeline.value) {
    pipeline.value.forEach(() => {
      open.value.push(false)
    })
  }
})
</script>
<style lang="scss">
.new-pipeline-module-component {
  @apply py-[30px];

  .small-btn {
    @apply flex items-center justify-center bg-progressive px-4 rounded-full h-[35px] text-[12px] xl:text-[14px] scale-100 transition-transform hover:scale-95;
    @apply text-center text-base not-italic font-normal leading-[22px] tracking-[-0.16px];
    @apply lg:w-[180px];
  }

  @apply bg-functional;

  .progress-bar-container {
    @apply w-full h-full bg-[#d3d8d8] lg:bg-transparent rounded-[500px];
    .progress-bar {
      @apply h-5 rounded-[10px];
      background: linear-gradient(
        270deg,
        var(--barfrom) -100%,
        var(--barto) 100%
      );
    }
  }

  .pipeline-bicolor-line {
    @apply w-[66px] h-[3px];
  }

  .inner-container {
    @apply px-[10px];

    .title {
      @apply flex flex-col gap-1 bg-white rounded-[10px] py-[15px] px-[10px] my-[5px] lg:px-[30px] lg:py-6;

      &-inner {
        @apply text-balanced text-base font-bold leading-5;
        @apply lg:text-[26px] lg:not-italic lg:font-medium lg:leading-[30px];
      }
    }

    .labels {
      @apply hidden -mt-[5px] mb-[5px] py-[5px] lg:flex rounded-[10px] bg-progressive;

      div {
        @apply flex items-center justify-center h-11 text-center border-black border-r-[1px] text-humble;

        &:first-child {
          @apply w-[15%];
        }

        &:nth-child(2) {
          @apply w-[30%];
        }

        &:nth-child(3) {
          @apply w-[27.5%];
        }

        &:nth-child(4) {
          @apply w-[27.5%] border-none;
        }
      }
    }

    .pipelines {
      @apply flex flex-col gap-4 lg:gap-[5px];

      .pipeline {
        @apply bg-white rounded-[10px] overflow-hidden;

        &-title {
          @apply min-h-[53px] flex items-center relative bg-approachable py-[14px] pl-3 pr-2 lg:pt-[10px] lg:pb-2 lg:px-[30px] lg:min-h-[38px];

          &-inner {
            @apply w-full max-w-[92%];

            p {
              @apply lg:text-base lg:not-italic lg:font-normal lg:leading-5;
            }
          }

          &-toggle {
            @apply absolute top-[14px] right-[8px] w-[25px] h-[25px] bg-white rounded-full shadow-md flex items-center justify-center lg:top-[6px] lg:right-[7px];

            img {
              @apply w-[10px] transition-all;
            }

            &.active {
              img {
                @apply rotate-180;
              }
            }
          }
        }

        &-inner {
          @apply border-b border-solid border-functional;
          background: linear-gradient(180deg, #70ffe5 11.89%, #fff 56.45%);
          .pipeline-content-container {
            @apply flex flex-col gap-8 pl-3 pr-[15px] lg:flex-row lg:px-[30px] lg:items-center pb-4 lg:pb-0;
          }
          &-text {
            @apply mt-10 text-base not-italic font-normal leading-[22px] tracking-[-0.16px] lg:mb-[60px] flex flex-col gap-2;
            p {
              @apply text-[16px] lg:text-[18px];
            }
          }

          &-trial {
            @apply flex flex-col gap-3 items-start border border-solid bg-white border-functional rounded-[10px] lg:mt-10 lg:mb-[50px] lg:min-w-[434px] lg:max-w-[434px] px-[30px] lg:px-[25px] py-[25px] mx-2 lg:mx-0;

            &-text {
              @apply text-base not-italic font-normal leading-[22px] tracking-[-0.16px];
            }
          }
        }

        &-mobile {
          @apply my-3 flex flex-col lg:hidden;

          &-approach {
            @apply flex flex-col gap-2 mb-3 pb-3 border-b border-solid border-functional;

            &:last-child {
              @apply pb-0 mb-0 border-b-0;
            }

            &-top {
              @apply mx-[10px] flex flex-col px-[11px];

              .kvp {
                @apply flex items-center;

                .label {
                  @apply w-20 text-[13px] not-italic font-medium leading-[120%] tracking-[-0.26px] font-FKGrotesk;
                }

                .value {
                  @apply w-[calc(100%_-_80px)] text-[13px] not-italic font-medium leading-[120%] tracking-[-0.26px];
                }

                .img-container {
                  @apply flex items-center relative;
                  .child-img {
                    @apply w-auto object-contain object-left h-[100%] max-h-[27px];
                  }
                  .superscript {
                    @apply relative text-[12px] leading-tight px-0 -left-1 -top-1;
                  }
                }
              }
            }

            &-bottom {
              @apply flex flex-col gap-1 mx-[10px];

              .stage {
                @apply px-[11px] text-xs not-italic font-normal leading-[120%] tracking-[-0.24px];
              }
            }
          }
        }

        &-desktop {
          @apply hidden lg:flex flex-col;

          &-item {
            @apply flex;

            @mixin spreadsheet {
              border-bottom: 1px solid #d3d8d8;
              border-left: 1px solid #d3d8d8;
              margin-top: -1px;
              margin-left: -1px;
            }

            .parent {
              @include spreadsheet;
              @apply w-[15%] flex items-center justify-center px-3 text-center;

              &:first-child {
                @apply border-l-0 border-b-0;
              }
            }

            .children {
              @apply flex flex-col w-[30%];
              .img-container {
                .child {
                  @apply border-none;
                }
                .superscript {
                  @apply relative text-[12px] leading-tight px-0 -left-2.5 -top-1;
                }
              }
              .child {
                @include spreadsheet;
                @apply px-[6.5px] py-[1px];

                &:last-child {
                  // @apply border-b-0;
                }
              }
              .img-container {
                @apply flex items-center;
                @include spreadsheet;
                .child-img {
                  @apply w-fit object-contain object-left h-[100%] max-h-[27px] mr-2;
                }
              }
            }

            .progress {
              @apply relative flex flex-col w-[55%];

              .progbar {
                @include spreadsheet;
                @apply border-r-0 py-[4px] pl-px z-1 h-[30px];
                height: 100%;
                .progress-bar-container {
                  .progress-bar {
                    @apply h-full rounded-[500px];
                  }
                }

                &:last-child {
                  @apply border-b-0;
                }
              }

              .divider {
                @apply absolute w-px h-full bg-[#d3d8d8] left-1/2 translate-x-[-50%];
              }
            }
          }
        }
      }
    }

    .disclaimer {
      * {
        @apply text-[12px] leading-tight text-right pb-2;
      }
    }
  }

  .other {
    .other-right {
      .other-right-content {
        ul {
          @apply list-disc;
          li::marker {
            @apply text-progressive;
          }
        }
      }
    }
  }
}
</style>
